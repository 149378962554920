import Analytics from "./AnalyticsSection/Analytics";
import Contact from "./ContactSection/Contact";
import Footer from "./Footer/Footer";
import HeroSection from "./HeroSection/HeroSection";
import NavBar from "./Navbar/NavBar";
import Portfolio from "./PortfolioSection/Portfolio";
import Process from "./ProcessSection/Process";
import Services from "./ServicesSection/Services";
import PricingModern from "./PricingSection/PricingModern";

export default function HomeEspañol(){
    return(
        <>
          <NavBar />
          <HeroSection />
          <Services />
          <Process />
          <Analytics />
          <PricingModern />
          <Portfolio />
          <Contact />
          <Footer />
        </>
    )
}