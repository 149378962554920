import Analytics from "./AnalyticsSection/Analytics";
import Portfolio from "./PortfolioSection/Portfolio"
import Contact from "./ContactSection/Contact";
import Footer from "./Footer/Footer";
import HeroSection from "./HeroSection/HeroSection";
import NavBar from "./Navbar/NavBar";
import Pricing from "./PricingSection/Pricing";
import PricingModern from "./PricingSection/PricingModern";
import Process from "./ProcessSection/Process";
import Services from "./ServicesSection/Services";

export default function Home(){
    return(
        <>
         <NavBar />
         <HeroSection/>
         <Services />
         <Process />
         <Analytics />
         <PricingModern />
         <Portfolio />
         <Contact />
         <Footer />
        </>
    )
}