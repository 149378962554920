import './services.css'
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

export default function Services(){

  useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);

    return(
        <>
        <div id="services" className="container-fluid pb-5 pt-4 mw-100">
           <div className="container text-center pt-3 pb-5">
               <h1 className="display-3 text-white">Services</h1>
               <p className='lead text-white'>Barcias Tech specializes in helping small to medium size businesses. Our services include
               web design, branding, digital marketing and much more. 
               We understand the budget constraints of small businesses thus we provide
               high quality affordable services.
               </p>
           </div>
           
           <div className='container bg-light p-4 rounded-3 shadow-lg'>
             {/* Web design section */} 
               <div className='row d-flex align-items-center pt-4 border-bottom pb-4'>
                  <div className='col-lg-6 col-md-6 my-auto' data-aos="zoom-in-up" data-aos-duration="1000">
                    <img className='img-thumbnail shadow-lg rounded-3' src={require('./Assets/web-design.jpg')} alt='web-design' />
                  </div>
                  <div className='col-lg-6 col-md-6' data-aos="zoom-in-up" data-aos-duration="1000">
                      <h1 className='display-5 pt-4 text-muted'>Web Design</h1>
                      <p className='lead pt-3'>A website that is mobile friendly
                      is essential in today's competitive market. A professionally crafted
                      website increases business credibility and revenue. 
                      Let us help you create a beautiful website to position
                      your business a head  of the competition.  </p>
                  </div>
               </div>
             {/* End of Web design section */} 

              {/* Ecommerce design section */} 
                <div className='row d-flex align-items-center pt-4 border-bottom pb-4'>
                  <div className='col-lg-6 col-md-6 my-auto' data-aos="zoom-in-up" data-aos-duration="1000">
                    <img className='img-thumbnail shadow-lg rounded-3' src={require('./Assets/ecommerce.png')} alt='web-design' />
                  </div>
                  <div className='col-lg-6 col-md-6' data-aos="zoom-in-up" data-aos-duration="1000">
                      <h1 className='display-5 pt-4 text-muted'>E-Commerce</h1>
                      <p className='lead pt-3'>Sell your products or services online. Depending on
                      your requirements and budget there are several options to choose from.  </p>
                  </div>
               </div>
             {/* Ecommerce of Web design section */} 

             {/* Branding section */} 
                <div className='row d-flex align-items-center pt-4 border-bottom pb-4'>
                  <div className='col-lg-6 col-md-6 my-auto' data-aos="zoom-in-up" data-aos-duration="1000">
                    <img className='img-thumbnail shadow-lg rounded-3' src={require('./Assets/branding.jpg')} alt='branding' />
                  </div>
                  <div className='col-lg-6 col-md-6' data-aos="zoom-in-up" data-aos-duration="1000">
                      <h1 className='display-5 pt-4 text-muted'>Digital Marketing</h1>
                      <p className='lead pt-3'>A successful business needs successful digital marketing. 
                      Our team of experts can help you create the perfect strategy for your business.
                      A great strategy utilizes a variety of digital channels such as search engine optimization (SEO), social media,
                      email campaings, and mobile friendly websites. 
                      </p>
                  </div>
               </div>
             {/* Social Media section */} 

             {/* Branding section */} 
             <div className='row d-flex align-items-center pt-4 border-bottom pb-5'>
                  <div className='col-lg-6 col-md-6 my-auto' data-aos="zoom-in-up" data-aos-duration="1000">
                    <img className='img-thumbnail shadow-lg rounded-3' src={require('./Assets/social-media.png')} alt='social-media' />
                  </div>
                  <div className='col-lg-6 col-md-6' data-aos="zoom-in-up" data-aos-duration="1000">
                      <h1 className='display-5 pt-4 text-muted'>Social Media</h1>
                      <p className='lead pt-3'>Maximize your business online presence. At Barcias, we will
                      help you build custom Social Media graphics and videos to standarize your brand and
                      give your business a professional look across all media channels. 
                      </p>
                  </div>
               </div>
             {/* Google Profile section */} 

             {/* Branding section */} 
                <div className='row d-flex align-items-center pt-4 border-bottom pb-4'>
                  <div className='col-lg-6 col-md-6 my-auto' data-aos="zoom-in-up" data-aos-duration="1000">
                    <img className='img-thumbnail shadow-lg rounded-3' src={require('./Assets/google.png')} alt='google' />
                  </div>
                  <div className='col-lg-6 col-md-6' data-aos="zoom-in-up" data-aos-duration="1000">
                      <h1 className='display-5 pt-4 text-muted'>Google Business Profile</h1>
                      <p className='lead pt-3'>We build optimized Google Business profiles. A 
                      great Google Business profile will increase traffic to your website and
                      physical location. And with the right integrations, your customers will be able 
                      to place orders online.
                      </p>
                  </div>
               </div>
             {/* End of Google Profile section */} 

             {/* Digital Media */} 
             <div className='row d-flex align-items-center pt-4 pb-4'>
                  <div className='col-lg-6 col-md-6 my-auto' data-aos="zoom-in-up" data-aos-duration="1000">
                    <img className='img-thumbnail shadow-lg rounded-3' src={require('./Assets/digital-media.png')} alt='google' />
                  </div>
                  <div className='col-lg-6 col-md-6' data-aos="zoom-in-up" data-aos-duration="1000">
                      <h1 className='display-5 pt-4 text-muted'>Digital Media</h1>
                      <p className='lead pt-3'>Our digital media team can help you shoot, edit, and create amazing
                      videos or graphics. A great webiste starts with great digital media.
                      </p>
                  </div>
               </div>
             {/*End of Digital Media */} 
             

           </div>
        </div> 
        </>
    )
}