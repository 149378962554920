import { PopupButton } from 'react-calendly'
import './hero.css'

export default function HeroSection(){
    return(
      <>
       <div id="hero-section" className='container fill mw-100'>
           <div className='container'>
           <div className="row hero">
               <div className="col-lg-12 hero-text" >
                   <h1 className='display-1 px-2 text-white '>Barcias Servicios Digitales </h1>
                   <p className='lead text-white'>Asistiendo pequeños y medianos negocios con todas sus 
                   necesidades digitales!</p>
                   <PopupButton className='btn btn-lg mt-2 btn-danger'
                      url="https://calendly.com/barciastech/barcias-consulta-gratuita"
                      /*
                       * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                       * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                       */
                      rootElement={document.getElementById("root")}
                      text="Agenda una llamada hoy!"
                     />
                   {/* <a href='#contact' className='btn btn-lg mt-2 btn-danger'>Agenda una llamada hoy!</a> */}
               </div>
               {/* <div className="col-lg-6 col-md-6">
               {/* <iframe className='hero-image' src="https://player.vimeo.com/video/721189588?h=78d35ae9d6" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> */}
                   {/* <img className='hero-image shadow-lg' src={require('./Assets/hero.png')} alt='hero' /> */}
               {/* </div> */}
           </div>
           </div>
       </div>
      </>
    )
}