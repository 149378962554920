import './footer.css'
import MyModal from '../Modal/MyModal'
import { useState } from 'react'
import TermsAndConditions from '../../Assets/TermsAndConditions'
export default function Footer(){
  const [ showModal, setShowModal] = useState(false)
    return(
      <>
        <div id="footer" className="container-fluid text-white pt-5">
        <div class="container">
                <div className='row d-flex align-content-center'>
                   <div className='col-lg-4 col-md-5 mx-auto'>
                      <h1 className='display-6'>Get in touch</h1>
                      <p className='lead'>Barcias Tech</p>
                      {/* <p a className='lead text-white'><i class="bi bi-phone"></i> <a className='lead text-white' href="tel:7083140477p000">(708) 314-0477</a></p> */}
                      <p className='text-white lead'><i class="bi bi-envelope"></i> <a className='lead text-white' href="mailto:sales@barciastech.com">sales@barciastech.com</a> </p>
                      <p className='lead'><i class="bi bi-geo-alt"></i> Chicago, IL 60617</p>
                   </div>
                   <div className="col-lg-8 col-md-7">
                     <iframe className="shadow-lg rounded-3" width="100%" height="250px" title='barciastech' id="gmap_canvas" src="https://maps.google.com/maps?q=11319%20S%20Green%20Bay%20Chicago%20IL%2060617&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                   </div>
                </div>
              </div>
            <div className="container text-center pt-5 pb-3">
                <a className='text-white' href="/">Barcias LLC, 2020</a>
                <p className='mt-2'></p>
                <a className='text-white' onClick={() => setShowModal(true)}  href='#lk'>Terms and Conditions</a>
            </div>
        </div>
        <MyModal show={showModal} onHide={() => {setShowModal(false)}}>
           <TermsAndConditions />
        </MyModal>
        </>
    )
}